 // Start of Selection
 import React, { useState, useRef, useEffect } from 'react';
 import { Search, ArrowRight, Users, BarChart2, PieChart, MessageCircle, ExternalLink, CheckCircle } from 'lucide-react';

const GrowthPlatform = () => {
  const chatContainerRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState([]); // 新增：存储对话消息
  const [showChat, setShowChat] = useState(false); // 新增：控制对话框显示
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  
  // 添加处理等待列表的函数
  const handleWaitlist = () => {
    setShowWaitlistConfirm(true);
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  const agents = [
    { 
      icon: <Users className="w-6 h-6" />,
      title: "Keyword analysis",
      description: "Competitive and keyword analysis.",
      creator: "getu.ai",
      link: "https://chatgpt.com/g/g-YKACAF1Nw-keyword-analysis-agent"
    },
    {
      icon: <BarChart2 className="w-6 h-6" />,
      title: "Seo strategist",
      description: "Comming soon",
      creator: "getu.ai",
      link: ""
    },
    {
      icon: <BarChart2 className="w-6 h-6" />,
      title: "Campaign planner",
      description: "Comming soon",
      creator: "getu.ai",
      link: ""
    },
    // ... 其他 topics
  ];
  const handleUserInput = async () => {
    if (inputValue.trim()) {
      setMessages(prev => [...prev, { type: 'user', content: inputValue }]);
      setShowChat(true);
      
      // 使用 setTimeout 确保在 DOM 更新后滚动
      setTimeout(scrollToBottom, 100);

      setMessages(prev => [...prev, { 
        type: 'system', 
        content: `Thank you for your inquiry! We recommend using <a href='https://chatgpt.com/g/g-YKACAF1Nw-keyword-analysis-agent' target='_blank' class='text-blue-500 hover:text-blue-700 underline'>Keyword analysis agent</a> to solve this problem. Keyword analysis agent is a professional competitive analysis tool that can help you better understand the market competition landscape.

You can also <a href='/growth-service' class='text-blue-500 hover:text-blue-700 underline'>click here</a> to schedule an appointment with our expert consultant.`
      }]);
      // 在系统回复后也滚动到底部
      setTimeout(scrollToBottom, 100);
      try {
        const response = await fetch('https://www.getu.ai/api/front_end/interfaces/homepage/user_input', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ input_text: inputValue }),
        });
        
        if (!response.ok) {
            const errorText = await response.text();
            console.error('API Error:', errorText);
            throw new Error(`Request failed: ${response.status}`);
        }
        
        const data = await response.json();
        return data;
        
    } catch (error) {
        console.error('Request failed:', error);
        throw error;
    }
      
      setInputValue(''); // Clear input box
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  
  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      handleUserInput();
    }
  };

  const handleTopicClick = (title) => {
    if (title === "Who are my competitors?") {
      window.location.href = "https://chatgpt.com/g/g-YKACAF1Nw-keyword-analysis-agent";
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-slate-100 p-6" style={{ paddingTop: "6rem" }}>
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-slate-900 mb-4">GETU</h1>
          <p className="text-lg text-slate-600">Your End-to-end AI-powered Growth Partner</p>
        </div>

        {/* Search Section */}
        <div className="bg-white rounded-xl shadow-lg p-6 mb-6 border border-amber-200/20">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-amber-600 w-5 h-5" />
            <input
              type="text"
              placeholder="Tell us the growth problem you would like to solve"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyPress}
              className="w-full pl-12 pr-4 py-4 rounded-lg bg-slate-50 border border-slate-200 focus:border-amber-400 focus:ring-2 focus:ring-amber-100 outline-none transition-all"
            />
            <button 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-amber-600 text-white rounded-full p-2"
              onClick={handleUserInput}
            >
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </div>
        {/* 对话展示区域 */}
        {showChat && (
          <div className="bg-white rounded-xl shadow-lg p-6 mb-6 border border-slate-200">
            <div 
              ref={chatContainerRef}
              className="space-y-6 max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
            >
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex items-start ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                  {/* 头像 */}
                  {message.type === 'system' && (
                    <div className="w-8 h-8 rounded-full overflow-hidden mr-3">
                      <img src="/test.png" alt="AI" className="w-full h-full object-cover" />
                    </div>
                  )}
                  
                  <div
                    className={`max-w-[80%] rounded-2xl p-4 ${
                      message.type === 'user'
                        ? 'bg-amber-500 text-white'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    <p className="text-sm leading-relaxed" 
                      dangerouslySetInnerHTML={{ __html: message.content }}>
                    </p>
                  </div>

                  {/* 用户头像 */}
                  {message.type === 'user' && (
                    <div className="w-8 h-8 rounded-full bg-gray-400 flex items-center justify-center ml-3">
                      <span className="text-white text-sm">U</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}



        {/* Topics Grid */}
        <div className="mb-12">
            <h2 className="text-2xl font-semibold text-slate-700 mb-6">Agents for growth</h2>
            <div className="border-2 border-gray-200 rounded-xl p-6 bg-white"> {/* 添加外部边框 */}
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                    {agents.map((agent, index) => (
                        <div 
                            key={index}
                            onClick={() => agent.link ? window.open(agent.link, '_blank') : null} // 如果link不为空则在新窗口打开链接
                            className="flex h-[104px] items-center gap-2.5 overflow-hidden rounded-xl px-3 py-4 bg-gray-50 hover:bg-slate-50 transition-all cursor-pointer border border-slate-200"
                        >
                            {/* 序号 - 减小宽度和间距 */}
                            <div className="text-md flex w-6 shrink-0 items-center justify-center font-semibold text-slate-600">
                                {index + 1}
                            </div>

                            {/* 内容区域 - 减小间距 */}
                            <div className="flex w-full flex-grow items-center gap-2 overflow-hidden"> {/* gap-4 -> gap-2 */}
                                {/* 图标 - 减小尺寸 */}
                                <div className="h-10 w-10 flex-shrink-0"> {/* h-12 w-12 -> h-10 w-10 */}
                                    <div className="overflow-hidden rounded-full bg-amber-50 p-2">
                                        {agent.icon}
                                    </div>
                                </div>

                                {/* 文字信息 */}
                                <div className="overflow-hidden text-ellipsis break-words">
                                    <span className="line-clamp-2 text-sm font-semibold leading-tight text-slate-900">
                                        {agent.title}
                                    </span>
                                    <span className="line-clamp-2 text-xs text-slate-500">
                                        {agent.description}
                                    </span>

                                    {/* 底部信息 */}
                                    <div className="mt-1 flex items-center gap-1 text-xs text-slate-400">
                                        <div className="flex items-center space-x-1">
                                            <span>Creator：{agent.creator}</span>
                                        </div>
                                        <span className="text-[8px]">•</span>
                                        <div className="flex items-center space-x-1">
                                            <MessageCircle className="h-3 w-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        {/* Contact Section */}
        <div className="bg-white rounded-xl shadow-lg border border-slate-200 p-16 text-center">
          <h2 className="text-2xl font-semibold text-slate-900 mb-4">Need More Help?</h2>
          <div className="max-w-md mx-auto">
            <div className="p-8">
              <div className="inline-flex items-center justify-center w-12 h-12 mb-4">
                <MessageCircle className="w-8 h-8 text-amber-500" />
              </div>
              <h3 className="text-xl font-medium text-slate-900 mb-2">Join Our Waitlist</h3>
              <p className="text-slate-600 mb-6">Get exclusive access to our expert consultation services</p>
              <button
                onClick={() => {
                  window.location.href = "/growth-service";
                  setShowWaitlistConfirm(true);
                }}
                className="inline-flex items-center px-8 py-3 bg-amber-500 text-white rounded-lg font-medium hover:bg-amber-600 transition-colors"
              >
                Join Waitlist
              </button>
            </div>
          </div>
        </div>



        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-80 text-center relative">
              <h2 className="text-xl font-bold mb-4">Coming Soon</h2>
              <p className="text-gray-600 mb-6">This feature is under development. Stay tuned!</p>
              <a
                href="/growth-service"
                className="inline-flex items-center px-8 py-3 bg-amber-500 text-white rounded-lg font-medium hover:bg-amber-600 transition-colors"
              >
                Learn More
              </a>
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-opacity opacity-70 hover:opacity-100"
              >
                <span className="material-icons">X</span>
              </button>
            </div>
          </div>
        )}

        {/* Footer */}
        <footer className="mt-12 text-center text-sm text-slate-500">
          <p>© 2024 GETU - Growth Solutions. All rights reserved.</p>
          <div className="mt-2">
            <a href="/privacy" className="hover:text-amber-600">Privacy Policy</a>
            <span className="mx-2">|</span>
            <a href="/terms" className="hover:text-amber-600">Terms of Service</a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default GrowthPlatform;